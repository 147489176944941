<template>
  <div>
    <HomePageTop/>
    <loginDialog ref="dialog"/>
    <header>
      <div>RAKUMART&1688 共同プロジェクト</div>
      <div>アリババグループ アカウント 連結登録</div>
    </header>
    <section>
      <div class="headerSection">
        <div class="sectionItemOneContainer">
          <div class="sectionItemOneLeftContainer">
            <div class="title">
              RAKUMARTは、会員様に最も便利な輸入ビジネスサポート・サービスを提供する事に尽力しています。
            </div>
            <div class="text margin-bottom-18">
              現在RAKUMARTサイトで会員登録すれば、自動的にアリババ・アカウントを取得する事が可能です。
            </div>
            <div class="text">
              この機能により、余分な煩わしい手続き不要で中国の大型ショッピング・サイトの豊富な商品情報を簡単に閲覧し、精度の高い中国輸入ビジネスを提供します。
            </div>
          </div>
          <img alt="" src="../../assets/accountSynchronization/picture1.png">
        </div>
      </div>
      <div class="footerSection">
        <h1>連結によるメリット</h1>
        <div class="sectionItemOneContainer" style="margin-bottom: 80px">
          <img alt="" src="../../assets/accountSynchronization/picture2.png">
          <div class="sectionItemOneLeftContainer">
            <div class="title" style="margin-bottom: 30px">時間と手間の節約</div>
            <div class="text" style="margin-bottom: 28px">
              RAKUMARTとAlibabaのアカウントを同期させることで、複数のプラットフォームに別々にアカウントを登録する手間が省けます。
            </div>
            <div class="text" style="margin-bottom: 28px">
              一つのステップでAlibabaのグループサイト（1688、Tmall、Taobao）を簡単にアカウント作成、閲覧、必要な商品を見つけることができます。
            </div>
            <div class="text">
              弊社はすべてを簡略化し、複雑な登録手続きを省きます。お客様の貴重な時間を節約し、迅速かつ効率的に注文を行えるようにさせていただきました。
            </div>
          </div>
        </div>
        <div class="sectionItemOneContainer">
          <div class="sectionItemOneLeftContainer">
            <div class="title" style="margin-bottom: 30px">凍結リスクの軽減</div>
            <div class="text">
              RAKUMARTで同期登録されたアカウントを利用することで、アカウントが海外で使用されることによってアカウントが停止されるリスクを大幅に少なくすることができます。
            </div>
          </div>
          <img alt="" src="../../assets/accountSynchronization/picture3.png">
        </div>
      </div>
    </section>
    <footer>
      <h1>今すぐ始める</h1>
      <div class="footerContainer">
        <div class="left">
          <div>RAKUMART未登録の方</div>
          <div @click="registerPageJump">新規登録へ</div>
        </div>
        <div class="right">
          <div>RAKUMARTアカウントお持ちの方</div>
          <div @click="showAssociationDialogVisible">連結へ</div>
        </div>
      </div>
    </footer>
    <Foot></Foot>
    <div class="newDialogContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="associationDialogVisible"
          width="580px"
      >
        <associationDialog @close="associationDialogVisible=false;"/>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import loginDialog from "@/components/public/loginDialog.vue";
import HomePageTop from "@/components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
import associationDialog from "@/views/UserCenter/views/userDetail/components/accountAssociationDialog.vue";

export default {
  components: {Foot, HomePageTop, associationDialog, loginDialog},
  data() {
    return {
      associationDialogVisible: false
    }
  },
  methods: {
    registerPageJump() {
      window.open('/register')
    },
    showAssociationDialogVisible() {
      let user_token = localStorage.getItem('japan_user_token');
      if (user_token != null) {
        this.associationDialogVisible = true;
      } else {
        this.$refs.dialog.visible = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  height: 400px;
  background: url("../../assets/accountSynchronization/banner.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 140px;
  box-sizing: border-box;

  div:first-child {
    color: #FFFFFF;
    font-size: 48px;
    text-align: center;
    margin-bottom: 40px;
  }

  div:last-child {
    color: #FFFFFF;
    text-align: center;
    font-size: 32px;
  }
}

section {
  .headerSection {
    width: 100%;
    background: #F6F6F6;
    padding: 80px 0 120px;
  }

  .margin-bottom-18 {
    margin-bottom: 18px;
  }

  .footerSection {
    background: #fff;
    padding: 124px 0 120px;

    h1 {
      color: #333333;
      font-size: 28px;
      margin-bottom: 90px;
      text-align: center;
      font-weight: bold;
    }
  }

  .sectionItemOneContainer {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sectionItemOneLeftContainer {
      width: 480px;

      .title {
        font-size: 28px;
        color: #333333;
        font-weight: bold;
        line-height: 42px;
        margin-bottom: 48px;
      }

      .text {
        line-height: 24px;
        color: #333333;
      }
    }

    img {
      width: 690px;
      height: 560px;
    }
  }
}

footer {
  padding: 124px 0 120px;
  background: #F6F6F6;

  h1 {
    font-size: 28px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }

  .footerContainer {
    width: 980px;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-between;

    .left, .right {
      width: 400px;
      height: 240px;
      background: #FFFFFF;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div:first-child {
        color: #333333;
      }
    }

    .left div:last-child {
      width: 160px;
      height: 56px;
      background: #B4272B;
      border-radius: 8px;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      line-height: 56px;
      margin-top: 23px;
    }

    .right div:last-child {
      width: 160px;
      height: 56px;
      background: #fff;
      border-radius: 8px;
      color: #B4272B;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      line-height: 56px;
      margin-top: 23px;
      border: 2px solid #B4272B;
    }
  }
}

.newDialogContainer /deep/ .el-dialog {
  border-radius: 10px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header, .el-dialog__body {
    padding: 0;
  }

  .dialogCenter {
    padding: 40px 0;

    div {
      color: #333333;
      font-weight: bold;
      text-align: center;
      font-size: 16px;
    }
  }

  .dialogFooter {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      line-height: 34px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>