<template>
  <div class="imageDialogPage">
    <div class="flexAndCenter">
      <img :src="img"/>
      <el-button type="primary" @click="$parent.$parent.showImageDialog()">リセット</el-button>
    </div>
    <div class="input">
      <el-input v-model="code" placeholder="画像内の文字列をご入力ください"/>
      <el-button type="primary" @click="returnCode">{{ $fanyi('确认') }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String
    }
  },
  data() {
    return {
      code: '',
    }
  },
  methods: {
    returnCode() {
      if (this.code == '') {
        return
      }
      this.$emit('submit', this.code)
    }
  }
}
</script>

<style lang="scss" scoped>
.imageDialogPage {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 50px;

  img {
    width: 340px;
    min-width: 340px;
    margin-right: 50px;
  }

  .input {
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 100%;

    /deep/ .el-input__inner, /deep/ .el-input {
      width: 340px;
      margin-right: 50px;
    }
  }

  /deep/ .el-button {
    min-width: 100px;
  }
}
</style>