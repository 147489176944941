<template>
  <div class="dialog">
    <div class="left">
      <div class="top">アカウント情報確認</div>
      <div class="center">
        <img alt="" src="../../../../../assets/userDetail/left.png">
        <div>既に1688またはtaobaoサイトでアカウント登録ご利用済みの携帯番号はご利用できませんので予めご了承ください。
        </div>
        <img alt="" src="../../../../../assets/userDetail/right.png">
      </div>
      <img alt="" src="../../../../../assets/userDetail/icon.png">
    </div>
    <div class="right">
      <div class="closeIcon" @click="$emit('close','')">x</div>
      <div v-if="step===0" class="zeroStepContainer">
        <div class="title">注意事項</div>
        <div class="scrollContainer">
          <div>
            1.こちらで登録されるアカウントについて、弊社は代わりに登録する作業を簡易にするものであり、所有権及び管理はお客様自身になります。<br>
            2.不正利用など発覚される場合はアリババの規則に従い凍結などされることがありますので、予めご了承ください。<br>
            3.　弊社サービスを利用せず、アリババアカウントのみご利用される場合や弊社への登録情報の虚偽等の不適切なご利用についても中止させて頂く事がございます。<br>
            4.通話が出来ないSMS・データ専用の番号でご登録の場合、緊急時や通関等のお取引に支障がございますので、ご利用を中止させて頂く事がございます。<br>
            5.アリババアカウントの転売や貸与、第三者利用が疑われる場合は、ご利用を中止させて頂きます。
          </div>
          <div class="btn" @click="step++">上記に同意、次へ</div>
        </div>
      </div>
      <div v-else-if="step===1" class="firstStepContainer">
        <div class="label">携帯番号：</div>
        <el-input ref="mobile" v-model="mobile" size="mini" style="width: 240px;margin-bottom: 10px"
                  @input="mobileInput"/>
        <div v-show="showCode" class="label">認証コード：</div>
        <el-input v-show="showCode" ref="code" v-model="code" size="mini" style="width: 240px;"
                  @blur="contrastVerificationCode">
          <template slot="append">
            <div @click="showImageDialog">{{ btnText }}</div>
          </template>
        </el-input>
        <div class="errorCodeText">{{ code !== '' && regCode === false ? $fanyi("验证码错误") : '' }}</div>
        <div class="label">Email：</div>
        <el-input ref="email" v-model="email" size="mini" style="width: 240px;margin-bottom: 20px"/>
        <div class="btn" @click="submit">記載完了、同期へ</div>
      </div>
      <div v-else class="theSecondStepContainer">
        <div class="header">
          <img alt="" src="../../../../../assets/userDetail/success.png">
          <div>アカウント同期作成完了
          </div>
        </div>
        <div class="section">
          <div class="sectionText">
            <div>＊アリババ初回のログインはパスワードが設定されていないため、携帯番号で認証コード取得する形でのログインになります。
            </div>
            <div>＊こちらのアカウントは日本国内のみのご利用になっており、VPNやIP変動ツールの利用により、アカウント凍結される場合がございますので、予めご了承ください。
            </div>
          </div>
          <div class="btn" style="margin:20px 0 10px" @click="pageJump">１６８８ログインへ</div>
        </div>
        <div class="footer">
          <span
              class="footerLeft">RAKUMART&1688アカウント連結サービスをご利用の方は、1688ホームページの短信登录（電話番号でのログイン）でログインするようにお願い致します。</span>
        </div>
      </div>
    </div>
    <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        width="580px"
    >
      <imageDialog ref="imageDialogs" :img="img" @close="dialogVisible=false;" @submit="sendCode"/>
    </el-dialog>
  </div>
</template>

<script>
import imageDialog from "@/components/public/imageDialog.vue"

export default {
  data() {
    return {
      step: 0,
      mobile: '',
      email: '',
      code: '',
      userData: {},
      img: '',
      regCode: false,
      showCode: false,
      dialogVisible: false,
      btnText: this.$fanyi("获取验证码")
    }
  },
  components: {
    imageDialog
  },
  created() {
    this.userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
    this.email = this.userData.email;
    this.mobile = this.userData.mobile;
    this.regCode=true;
  },
  methods: {
    download() {
      window.open('https://chrome.google.com/webstore/detail/rakumart%E4%BB%A3%E8%A1%8C%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88new/kiigkmeopkjhbpoijmdkolcpiaibfjjg?utm_source=ext_sidebar&hl=ja')
    },
    pageJump() {
      window.open('https://www.1688.com/')
    },
    mobileInput() {
      if (isNaN(this.mobile)) {
        this.$message.error(this.$fanyi("手机号输入错误"));
        this.mobile = '';
        this.$refs.mobile.focus();
      }
      if(this.userData.mobile != this.mobile){
        this.showCode=true;
        this.regCode=false;
      }else{
        this.showCode=false;
        this.regCode=true;
      }
    },
    contrastVerificationCode() {
      if (this.code != '') {
        this.$api.checkVerificationCode({
          number: this.mobile,
          code: this.code,
        }).then((res) => {
          if (res.success == false) {
            this.regCode = false;
            this.$refs.code.focus();
          } else {
            this.regCode = true;
          }
        });
      }
    },
    sendCode(val) {
      if (this.btnText == this.$fanyi("获取验证码") && this.mobile !== '') {
        let list = {
          number: this.mobile,
          captcha: val,
          is1688AccountCreate: 1
        };
        this.$api.sendVerificationCode(list).then((res) => {
          if (res.code != 0) {
            this.$message.error(res.msg)
            return
          }
          this.dialogVisible = false;
          this.$refs.imageDialogs.code = '';
          this.Countdown = 60;
          this.btnText = this.Countdown + ' S';
          var timer = setInterval(() => {
            this.Countdown--;
            this.btnText = this.Countdown + ' S';
            if (this.Countdown <= 0) {
              this.btnText = this.$fanyi("获取验证码");
              this.Countdown = 60;
              clearInterval(timer);
            }
          }, 1000);
        });
      }
    },
    showImageDialog() {
      if (this.btnText == this.$fanyi("获取验证码") && this.mobile !== '') {
        let code = ''
        for (var i = 0; i < 7; i++) {
          code += parseInt(Math.random() * 10);
        }
        let obj1 = {
          number: this.mobile,
        }
        this.$api.getCodeImg(obj1, code).then((res) => {
          this.img = res.data.image;
          this.dialogVisible = true;
        });
      }
    },
    submit() {
      if (this.mobile === '') {
        this.$message.error(this.$fanyi("手机号为空"));
        this.$refs.mobile.focus();
        return
      }
      if (this.email === '') {
        this.$message.error(this.$fanyi("请输入您的邮箱"));
        this.$refs.email.focus();
        return
      }
      if (this.regCode == false) {
        this.$message.error(this.$fanyi("验证码错误"));
        this.$refs.code.focus();
        return
      }
      this.$api.alibabaUserRegister({
        email: this.email,
        mobile: this.mobile,
        realname: this.userData.realname,
        id: this.userData.operation_id
      }).then((res) => {
        if (res.code != 0) {
          if (res.data == 'email') {
            this.$refs.email.focus();
          } else {
            this.$refs.mobile.focus();
          }
          this.$confirm(
              res.msg,
              '注意',
              {
                confirmButtonText: this.$fanyi('确定'), //确定
                showCancelButton: false,
                showConfirmButton: false,
                type: "warning",
                center: true,
                dangerouslyUseHTMLString: true,
              }
          ).then(() => {

          })
          return
        }
        this.$message.success(res.msg);
        this.step++
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  height: 350px;
}

.left {
  width: 280px;
  height: 350px;
  background: url("../../../../../assets/userDetail/bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 46px 0 0 40px;

  .top {
    color: #FC5446;
    font-weight: 800;
    font-size: 24px;
  }

  .center {
    margin: 12px 0 20px 0;
    display: flex;
    align-items: center;

    img {
      width: 20px;
    }

    div {
      line-height: 18px;
      color: #FC5446;
      font-size: 12px;
      margin: 0 5px;
    }
  }

  img {
    width: 200px;
  }
}

.right {
  width: 300px;
  padding-left: 30px;
  box-sizing: border-box;

  .closeIcon {
    margin: 20px 0 8px 230px;
    color: #B4272B;
    font-size: 19px;
    cursor: pointer;
  }

  .btn {
    width: 240px;
    height: 34px;
    background: #B4272B;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
  }

  .zeroStepContainer {

    .title {
      color: #333333;
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: -18px;
    }

    .scrollContainer {
      height: 276px;
      overflow-x: scroll;
      padding-bottom: 20px;

      div:first-child {
        line-height: 18px;
        color: #999999;
        font-size: 12px;
        margin-bottom: 20px;
        width: 240px;
      }
    }

  }

  .firstStepContainer {

    .label {
      color: #333333;
      font-size: 14px;
      margin-bottom: 10px;
    }

    .errorCodeText {
      font-size: 12px;
      color: #B4272B;
      margin: 10px 0;
    }

    /deep/ .el-input-group__append {
      padding: 0 10px;
      background: #fff;
      color: #B4272B;
      cursor: pointer;
    }
  }

  .theSecondStepContainer {
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        width: 30px;
        margin-right: 10px;
      }

      div {
        font-weight: 600;
        color: #333333;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .sectionText {
      div {
        line-height: 18px;
        color: #999999;
        font-size: 12px;
        width: 240px;
      }
    }

    .footer {
      width: 240px;

      .footerLeft {
        color: #999999;
        font-size: 12px;
        display: inline-block;
      }

      .footerRight {
        text-decoration: underline;
        font-size: 12px;
        color: #B4272B;
        cursor: pointer;
        display: inline-block;
        margin-left: 115px;
      }

      //div:first-child {
      //  line-height: 18px;
      //  color: #999999;
      //  font-size: 12px;
      //}
      //
      //div:last-child {
      //  text-decoration: underline;
      //  font-size: 12px;
      //  color: #B4272B;
      //}
    }
  }
}

.newDialogContainer /deep/ .el-dialog {
  border-radius: 10px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header, .el-dialog__body {
    padding: 0;
  }

  .dialogCenter {
    padding: 40px 0;

    div {
      color: #333333;
      font-weight: bold;
      text-align: center;
      font-size: 16px;
    }
  }

  .dialogFooter {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      line-height: 34px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>